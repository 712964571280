import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';


import { useCallback } from "react";
import Particles from "react-particles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.

function App() {

  const particlesInit = useCallback(async engine => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadFull(engine);
    await loadSlim(engine);
}, []);

const particlesLoaded = useCallback(async container => {
    await console.log(container);
}, []);



  return (




    
    <div className="App">



      <header className="App-header">
      <div
      style={{
        backgroundColor: 'whitesmoke',
        minWidth: '100vw',
        minHeight: '100vh',
        alignContent:'center',
        justifyContent:'center'
      }}>

<Container style={{width: "100vw", 
                  minHeight: "100vh",
                  background: 'whitesmoke',
                  alignContent:'center',
                  justifyContent:'center',
                  alignItems:'center',
                  justifyItems:'center',
                  textAlign: 'center',
                  paddingLeft: '0vh',
                  paddingRight: '0vh',
                  position: 'relative'}}>

<Card style={{maxWidth:{sm:'80%', 
                        md:'10%',},
              minHeight: "80vh",
              maxHeight: "100vh",
              alignContent:'center',
              justifyContent:'center',
              alignItems:'center',
              justifyItems:'center',
              display: 'flex',
              textAlign: 'center',
              background: 'white',
              marginTop: '5vh',
              marginBottom: '0vh',
              fontFamily: 'Quicksand',
              backgroundColor:'white',
                            borderColor:'#edebeb',
                            shadow:'medium',
                            color:'black',
                            borderRadius:'0px'}}>              
  <div  
  style={{
    width:'100%',
    maxHeight: "80vh",
}}>




<Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: "whitesmoke",
                    },
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: false,
                            mode: "push",
                        },
                        onHover: {
                            enable: false,
                            mode: "repulse",
                        },
                        resize: false,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#bab8b8",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: false,
                        opacity: 0.5,
                        width: 1,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: "none",
                        },
                        random: true,
                        speed: 1,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 90,
                    },
                    opacity: {
                      anim: {
                          enable: true,
                          speed: 1,
                          opacity_min: 0.05,
                          opacity_max: 0.5
                      }
                  },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 0.1, max: 2 },
                    },
                },
                detectRetina: true,
            }}
        />


<img src='1769669.svg' alt='deco' style={{maxWidth:'40vh', 
  marginTop:'-10vh'}}/>

              <h2 style={{marginTop:'0%'}}>
              Save the date
              </h2>
              <h6 style={{marginTop:'10%'}}>
            BRYLLUP
              </h6>
              <h4 style={{marginTop:'1vh'}}>
              Freja & Johannes
              </h4>
              <h5 style={{marginTop:'10%'}}>
              04.05.2024
              </h5>
              <Button style={{marginTop:'10%', 
                            maxWidth:'50%',
                            backgroundColor:'white',
                            borderColor:'#dbdbdb',
                            shadow:'medium',
                            color:'black',
                            borderRadius:'0px',
                            boxShadow: '0px 0px 0px #000000'
                            }}
                            href='webcal://p130-caldav.icloud.com/published/2/MTE0MTE2MjM2ODExNDExNvlQ_RXaFR_LMkjICUFFPhC3GhnsnSQa8_-psXpJTJywEABhX3pqYML4JZ1M6W_hMzmxrME6d31oqjG-hgmAdNk'>
              Tilføj til kalendar
              </Button>

              <p style={{fontSize:'0.6rem', paddingTop:'10px'}}>
                Tryk → Abonner → Tilføj → Ok
              </p>
              
  </div>
      </Card>





</Container>


      </div>
       
       
      
      </header>
    </div>
  );
}

export default App;
